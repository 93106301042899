<template>
  <b-row>
    <b-col md="6">
      <b-card-code no-body title="Create new toolkit category">
        <b-card-body>
          <validation-observer ref="toolkitCategoryValidation">
            <div class="mt-1">
              <label>Name</label>
              <validation-provider
                #default="{ errors }"
                name="name"
                rules="required"
              >
                <b-form-input v-model="name" type="text" placeholder="Category name" />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </div>
            <div class="mt-1">
              <label>Color</label>
                <b-form-input v-model="color" type="text" placeholder="#FF0000" />
            </div>

            <div class="mt-2">
              <b-button variant="primary" @click="createCategory">Create</b-button>
            </div>
          </validation-observer>
        </b-card-body>
      </b-card-code>
    </b-col>
  </b-row>
</template>

<script>
import BCardCode from "@core/components/b-card-code/BCardCode.vue";
import {
  BCardBody,
  BCardText,
  BFormInput,
  BRow,
  BCol,
  BButton,
} from "bootstrap-vue";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import { required } from "@validations";
export default {
  components: {
    BCardCode,
    BCardBody,
    BCardText,
    BFormInput,
    BRow,
    BCol,
    BButton,
    ValidationProvider,
    ValidationObserver,
  },
  data() {
    return {
      name: "",
      color: "",
      required,
    };
  },
  methods: {
    createCategory() {
      this.$refs.toolkitCategoryValidation.validate().then((success) => {
        if (success) {
            let category = {
                name: this.name,
                color: this.color
            }

          // Request to create toolkit category
          this.$http
            .post("/api/auth/admin/toolkit-categories", category)
            .then((res) => {
              if (res != undefined) {
                if (res.status == 201) {
                  // Redirect to new toolkit category page
                  this.$router.push({
                    name: "learn-and-develop-categories",
                  });
                }
              }
            })
            .catch((err) => {
              console.log(err);
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: "Something went wrong, please login again",
                  icon: "AlertCircleIcon",
                  variant: "danger",
                },
              });
            });
        }
      });
    },
  },
};
</script>
